import React, {Fragment, useEffect, useRef, useState} from "react";
import "./App.css"
import axios from "axios";
import bot from "./img/bot.png";
import chatLogo from "./img/chat-logo.png";
import userIcon from "./img/user.jpg";
import moment from "moment";
// import https from "https";

const getFormatedDateTime = () => {
  return moment(new Date()).format("HH:mm A | MMM DD")
}

const Chatbot = () => {
  const [messages, setMessages] = useState([{role: 'dot', content: "Hello! This is Dot. How can I help you?", timestamp: getFormatedDateTime()}]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const messageEndRef = useRef(null);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);


  const handleSubmit = (e) => {
    e.preventDefault();
    if(input.trim() !== '') {
      setLoading(true);
      const newMessage = {role: 'user', content: input, timestamp: getFormatedDateTime()};
      const temp = [...messages, newMessage] 
      setMessages(temp);

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://botevolve.hpedelta.com/generate',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : JSON.stringify({messages: [...temp]}),
      };

      setInput('');
      axios.request(config).then((response)=> {
        console.log(response);
        setTimeout(()=> {
          setMessages([...temp, {role: 'dot', content: response.data.response, timestamp: getFormatedDateTime()}]);
        }, 1000)
        setLoading(false);
      }).catch((error)=> {
        console.log(error);
        setLoading(false);
      })

    } 
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

const renderedChats = () => {
  return(
    <Fragment>
      {messages.map((msg, idx)=> (
        <Fragment key={"msg_"+msg.role+"_"+idx}>

          {msg.role ==="user" ? (
            <div className="outgoing-chats">
              <div className="outgoing-chats-img">
                <img src={userIcon} />
              </div>
              <div className="outgoing-msg">
                <div className="outgoing-chats-msg">
                  <p className="multi-msg">
                  {msg.content.split('\n').map((line, index) => (
                      <React.Fragment key={"msg_Content_"+msg.role+"_"+ index}>
                        {line}
                        {index < msg.content.split('\n').length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </p>
                  <span className="time">{msg.timestamp}</span>
                </div>
              </div>
            </div>
          ):(
            <div className="received-chats">
              <div className="received-chats-img">
                <img src={bot} />
              </div>
              <div className="received-msg">
                <div className="received-msg-inbox">
                  <p>
                  {msg.content.split('\n').map((line, index) => (
                      <React.Fragment key={"msg_Content_"+msg.role+"_"+ index}>
                        {line}
                        {index < msg.content.split('\n').length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </p>
                  <span className="time">{msg.timestamp}</span>
                </div>
              </div>
            </div>
          )}
          </Fragment>
        ))}
    </Fragment>
  )
}

  return (
    <div className="container">
        <div className="msg-header">
          <div className="header-content">
            <img src={bot} className="msgimg" />
            <div className="active">
              <p>Username</p>
            </div>
          </div>
          <div className="headerbtn">
          </div>
        </div>
        <div className="online">
            <div className="dotgreen"></div>
            <p>We are online</p>
        </div>
        <div className="chat-page">
          <div className="msg-inbox">
            <div className="chats">
              <div className="msg-page">
                {renderedChats()}
                {loading && (
                  <div className="received-chats">
                  <div className="received-chats-img">
                    <img src={bot} />
                  </div>
                  <div className="received-msg">
                    <div className="received-msg-inbox">
                    <div className="typing-indicator">
                      <p style={{width: "fit-content !important"}}>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                        <span className="dot">.</span>
                      </p>
                    </div>
                      <span className="time"></span>
                    </div>
                  </div>
                </div>
                )}
                <div ref={messageEndRef} />
              </div>
            </div>

            <div className="msg-bottom">
              <div className="input-group">
                  <div>
                    <div style={{display: "flex", alignItems: "center"}}>
                    <textarea 
                      className="form-control"
                      id="exampleFormControlTextarea1" 
                      placeholder="Type something..." 
                      value={input}
                      onChange={(e)=> {
                        setInput(e.target.value)
                      }}
                      onKeyDown={handleKeyDown}
                      rows="2"></textarea>
                      <a href="#/" onClick={handleSubmit} className="send-icon"><i className="bi bi-send"></i></a>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  );

}

export default Chatbot;